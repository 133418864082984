import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useMediaQuery, InputLabel, Select, MenuItem, FormControl, Button, TextField, Link, Snackbar, IconButton, Tooltip, OutlinedInput, InputAdornment, FormLabel, Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import LogoImage from './LogoImage';
import { useCatch } from '../reactHelper';
import fetchFunc from '../fetchFunc';
import useQuery from '../common/util/useQuery';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { atotoErrorMessagesActions } from '../store';
import { atotoRequest } from '../fetchFunc'

export const langCodeMap = {
  en: 1,
  ja: 2,
  de: 3,
  fr: 4,
  it: 5,
  es: 6,
  zh: 7,
  ru: 8,
  ar: 9,
  pt: 10,
}

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  extraContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  registerButton: {
    minWidth: 'unset',
  },
  resetPassword: {
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();
  const query = useQuery();

  const atotoErrorMessages = useSelector((state) => state.atotoErrorMessages.messages);
  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  // const [email, setEmail] = usePersistedState('loginEmail', '');
  const [username, setUsename] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const registrationEnabled = useSelector((state) => state.session.server.registration);
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);
  const emailEnabled = useSelector((state) => state.session.server.emailEnabled);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const announcement = useSelector((state) => state.session.server.announcement);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetchFunc('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const getBindInfo = async (email, uniqueId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/gps/customer/bindInfo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, uniqueId }),
      });
      if (response.ok) {
        const res = await response.json()
        if (res.data === true) return true
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loginTrccar = async (email, goRegister, nickName) => {
    try {
      const response = await fetchFunc('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&${undefined}=${false}`),
      });
      if (response.ok) {
        const auth = "Basic " + btoa(encodeURI(`${email}:${password}`))
        // window.sessionStorage.setItem('basicAuth', auth);
        const user = await response.json();
        generateLoginToken();
        dispatch(sessionActions.updateUser(user));
        const deviceId = query.get('deviceId')
        if (!deviceId) return navigate('/')
        const isCurrentBind = await getBindInfo(email, deviceId)
        if (isCurrentBind) {
          navigate('/');
        } else {
          navigate(`/settings/device?uniqueId=${deviceId}&psw=${btoa(password)}`)
        }
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // 账户密码校验已在商城登录接口校验，这里只有可能是账户未在trccar注册问题
      goRegister && goRegister(email, nickName)
      // setFailed(true);
      // setPassword('');
    }
  }

  // 如果登录，是商城用户，但是不是trccar用户，则后台去注册trccar并与商城用户绑定
  const atotoUserRegisterTrccar = async (email, nickName) => {
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/gps/customer/checkOrCreateAUserInTraccar`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: nickName, email, password }),
    });

    if (!response.ok) throw Error(await response.text());
    const res = await response.json();
    if (res.code !== 200) {
      // setFailed(true);
      if (`${res.code}`.startsWith('600')) {
        setErrorMessage(atotoErrorMessages[res.code] || res.message)
        setErrorSnackbarOpen(true)
      }
      setPassword('');
      return
    }
    //绑定成功后再次登录
    loginTrccar(email)
  }

  const handlePasswordLogin = async (event) => {
    event.preventDefault();
    // 商城登录
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-user/user/mallUserLogin`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });
    if (!response.ok) {
      setLoading(false)
      throw Error(await response.text());
    }
    const res = await response.json();
    if (res.code !== 200) {
      // setFailed(true);
      if (`${res.code}`.startsWith('600')) {
        setErrorMessage(atotoErrorMessages[res.code] || res.message)
        setErrorSnackbarOpen(true)
      }
      setPassword('');
      return setLoading(false)
    }

    const { email, nickName } = res.data

    // 商城登录成功再请求tarccar登录
    await loginTrccar(email, atotoUserRegisterTrccar, nickName)
    setLoading(false)
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetchFunc(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate('/');
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && username && password) {
      handlePasswordLogin(e);
    }
  };

  const getErrorMessage = useCatch(async () => {
    const res = await atotoRequest(`/atoto-product-core/dict/lang/getTranslatesByTopDictKey?dictKey=PROMPT&langType=${langCodeMap[language] || 1}`)
    if (res.success && res.data) {
      const msgMap = {}
      res.data.forEach((item) => {
        msgMap[item.dictCode] = item.dictValue
      })
      dispatch(atotoErrorMessagesActions.set(msgMap))
    }
  })

  useEffect(() => nativePostMessage('authentication'), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  useEffect(() => {
    getErrorMessage()
  }, [language])

  return (
    <LoginLayout>
      <div className={classes.options}>
        {nativeEnvironment && (
          <Tooltip title={t('settingsServer')}>
            <IconButton onClick={() => navigate('/change-server')}>
              <LockOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.container}>
        {useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.primary.main} />}
        <TextField
          required
          // error={failed}
          label={t('loginUserName')}
          name="username"
          value={username}
          autoComplete="email"
          autoFocus={!username}
          onChange={(e) => setUsename(e.target.value)}
          onKeyUp={handleSpecialKey}
        // helperText={failed && 'Invalid username or password'}
        />
        {/* <TextField
          required
          error={failed}
          label={t('userPassword')}
          name="password"
          value={password}
          type="password"
          autoComplete="current-password"
          autoFocus={!!email}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handleSpecialKey}
        /> */}
        <FormControl variant="outlined">
          <InputLabel htmlFor="user-password">{t('userPassword')}</InputLabel>
          <OutlinedInput
            required
            error={failed}
            label={t('userPassword')}
            name="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            autoFocus={!!username}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleSpecialKey}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {/* <Button
          onClick={handlePasswordLogin}
          onKeyUp={handleSpecialKey}
          variant="contained"
          color="secondary"
          disabled={!username || !password}
        >
          {t('loginLogin')}
        </Button> */}
        <LoadingButton
          loading={loading}
          onClick={handlePasswordLogin}
          onKeyUp={handleSpecialKey}
          variant="contained"
          color="secondary"
          disabled={!username || !password}
        >
          {t('loginLogin')}
        </LoadingButton>
        <div className={classes.extraContainer}>
          <Button
            className={classes.registerButton}
            onClick={() => {
              // const search =  query.deviceId ? `?deviceId`
              navigate('/register')
            }}
            disabled={!registrationEnabled}
            color="secondary"
          >
            {t('loginRegister')}
          </Button>
          {languageEnabled && (
            <FormControl fullWidth>
              <InputLabel>{t('loginLanguage')}</InputLabel>
              <Select label={t('loginLanguage')} value={language} onChange={(e) => setLanguage(e.target.value)}>
                {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
              </Select>
            </FormControl>
          )}
        </div>
        {emailEnabled && (
          <Link
            // onClick={() => navigate('/reset-password')}
            onClick={() => window.open(`${process.env.REACT_APP_PREFIX_ATOTO}/login`, `_blank`)}
            className={classes.resetPassword}
            underline="none"
            variant="caption"
          >
            {t('loginForgotPass')}
          </Link>
        )}
        <FormLabel>
          <Typography variant="body2" gutterBottom>
            {t('loginAlreadyRegister')}
          </Typography>
        </FormLabel>
      </div>
      <Snackbar
        open={!!announcement && !announcementShown}
        message={announcement}
        action={(
          <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />
      <Snackbar
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        message={errorMessage}
        autoHideDuration={3000}
      />
    </LoginLayout>
  );
};

export default LoginPage;
